import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import { IoIosArrowDown } from 'react-icons/io';
import Seo from '../components/seo';
import { useStaticQuery, graphql } from 'gatsby';

function Page({ pageContext }) {
  const data = useStaticQuery(
    graphql`
      query page {
        allMdx {
          edges {
            node {
              frontmatter {
                title
                excerpt
                featImg
                altImg
                category
                cta
                metaDesc
                outreach
                ogDesc
                ogImage
                twDesc
                twImage
              }
              slug
            }
          }
        }
      }
    `,
  );
  let seo;
  const page = pageContext;
  const { location } = pageContext;
  data.allMdx.edges.forEach((edge) => {
    if (edge.node.frontmatter.title == page.frontmatter.title) {
      seo = edge.node;
    }
  });

  const pathname = location?.pathname || pageContext?.fields?.slug;

  console.log(seo.frontmatter);
  return (
    <Layout>
      <div className="article">
        {/* <pre>{JSON.stringify(page)}</pre> */}
        <Seo
          title={seo.frontmatter.title}
          twDesc={seo.frontmatter.twDesc || seo.frontmatter.metaDesc || ''}
          ogDesc={seo.frontmatter.ogDesc || seo.frontmatter.metaDesc || ''}
          description={seo.frontmatter.metaDesc || ''}
          twImage={seo.frontmatter.twImage || seo.frontmatter.featImg || ''}
          ogImage={seo.frontmatter.ogImage || seo.frontmatter.featImg || ''}
          pathname={pathname}
          image={seo.frontmatter.featImg || ''}
        ></Seo>
        <section className="header">
          <h1>{page.frontmatter.title}</h1>
        </section>
        <div className="img-wrapper">
          {page.frontmatter.featImg && (
            <img
              src={page.frontmatter.featImg}
              alt={page.frontmatter.altImg}
              width="750"
              height="323"
            />
          )}
        </div>
        <section className="underline">
          <MDXRenderer>{page.body}</MDXRenderer>
        </section>
      </div>
      <div className="cta-wrapper">
        <div className="arrow">
          <IoIosArrowDown />
        </div>
        <a
          href={
            page.frontmatter.outreach
              ? '/shagi/?outreach=' + page.frontmatter.outreach
              : '/shagi'
          }
          aria-label="Четыре шага к Миру с Богом"
        >
          <h2>
            <span className="cta-underline">{page.frontmatter.cta}</span>
          </h2>
        </a>
      </div>
    </Layout>
  );
}

export default Page;
